import { useCallback } from 'react';

import { phone } from 'b2utils';
import { TaxRegime } from 'utils/enums';
import regex from 'utils/regex';

import { useApi } from '@contexts/Api';
import { endpoints } from '@contexts/Api';
import { useAuth } from '@contexts/Auth';
import { useScope } from '@contexts/Scope';

const useCompanies = () => {
  const { request } = useApi();
  const { scope } = useScope();
  const { hasAdminPermission, isManagementUser } = useAuth();

  const generateCompanyObject = useCallback(
    (company: ICompanyFormRequest) => {
      const companyPhone = phone.toObject(company.phone);

      const getTransportFields = () => {
        if (!hasAdminPermission) {
          return;
        }

        const enableEmissionFields = {
          cte_emission_is_enabled: company.cteEmissionIsEnabled,
          mdfe_emission_is_enabled: company.mdfeEmissionIsEnabled,
        };

        const transportFields = {
          digital_certificate: company.digitalCertificate?.id,
          digital_certificate_password: company.digitalCertificatePassword,
          cte_series: company.cteEmissionIsEnabled ? company.cteSeries : null,
          initial_cte_number: company.cteEmissionIsEnabled
            ? company.initialCteNumber
            : null,
          mdfe_series: company.mdfeEmissionIsEnabled
            ? company.mdfeSeries
            : null,
          initial_mdfe_number: company.mdfeEmissionIsEnabled
            ? company.initialMdfeNumber
            : null,
        };

        if (isManagementUser) {
          return {
            ...enableEmissionFields,
            ...transportFields,
          };
        } else {
          return transportFields;
        }
      };

      return {
        logo: company.logo.id,
        primary_color: company.primaryColor,
        secondary_color: company.secondaryColor,
        name: company.name,
        scope: company.scope,
        cnpj: company.cnpj.replace(regex.onlyNumbers, ''),
        antt: company.antt,
        state_registration: company.stateRegistration,
        email: company.email,
        phone: {
          area_code: companyPhone.areaCode,
          number: companyPhone.number,
        },
        budget_expiration: company.budgetExpiration,
        website_url: company.webSiteUrl,
        address: {
          city: company.address.city.id,
          zip_code: company.address.zipCode.replace(regex.onlyNumbers, ''),
          country: company.address.district,
          additional_info: company.address.complement,
          district: company.address.district,
          street: company.address.street,
          number: company.address.number,
        },
        insurer_name: company.insurerName,
        insurer_cnpj: company.insurerCnpj.replace(regex.onlyNumbers, ''),
        insurer_policy_number: company.insurerPolicyNumber,
        insurer_value_limit_per_load: company.insurerValueLimitPerLoad.replace(
          regex.onlyNumbers,
          ''
        ),
        is_load_unloading_operation: company.isLoadUnloadingOperation,
        is_load_lifting: company.isLoadLifting,
        is_internal_removal: company.isInternalRemoval,
        is_tracked: company.isTracked,
        is_automatic_endorsement: company.isAutoEndorsement,
        is_escorted: company.isEscorted,
        is_self_owned_vehicle: company.isSelfOwnedVehicle,
        atm_username: company.atmUsername,
        atm_cod: company.atmCod,
        atm_password: company.atmPassword || undefined,
        tax_regime: company.taxRegime,
        tax_regime_code: company.taxRegimeCode,
        rates:
          company.taxRegime === TaxRegime.SIMPLE_NATIONAL
            ? null
            : company.rates,

        ...getTransportFields(),
      };
    },
    [isManagementUser, hasAdminPermission]
  );

  const listCompanies = useCallback(
    async (params: IQueryParams) => {
      const response = await request<IPaginated<ICompany>>({
        method: 'get',
        url: endpoints.companies.list,
        headers: { scope },
        params: {
          page: params.page,
          search: params.search,
          is_active: params.isActive,
        },
      });
      return response.data;
    },
    [request, scope]
  );

  const listCompaniesWithoutScope = useCallback(
    async (params: IQueryParams) => {
      const response = await request<IPaginated<ICompany>>({
        method: 'get',
        url: endpoints.companies.list,
        params: {
          page: params.page,
          search: params.search,
          is_active: params.isActive,
        },
      });
      return response.data;
    },
    [request]
  );

  const getCompany = useCallback(
    async (id: string) => {
      const response = await request<ICompany>({
        method: 'get',
        url: endpoints.companies.get.replace(':companyId', id),
        headers: { scope },
      });

      return response.data;
    },
    [request, scope]
  );

  const createCompany = useCallback(
    async (company: ICompanyFormRequest) => {
      const response = await request<ICompany>({
        method: 'post',
        url: endpoints.companies.create,
        data: generateCompanyObject(company),
        headers: { scope },
      });
      return response.data;
    },
    [generateCompanyObject, request, scope]
  );

  const checkCompanyField = useCallback(
    async (company: { scope?: string; email?: string }) => {
      const response = await request<ICheckAvailableFields>({
        method: 'post',
        url: endpoints.companies.checkField,
        data: company,
      });
      return response.data;
    },

    [request]
  );

  const updateCompany = useCallback(
    async (id: string, company: ICompanyFormRequest) => {
      const response = await request<ICompany>({
        method: 'patch',
        url: endpoints.companies.update.replace(':companyId', id),
        data: generateCompanyObject(company),
        headers: { scope },
      });
      return response.data;
    },
    [generateCompanyObject, request, scope]
  );

  const deleteCompany = useCallback(
    async (id: number, isActive: boolean) => {
      const response = await request<void>({
        method: 'patch',
        url: endpoints.companies.delete.replace(':companyId', id.toString()),
        data: { is_active: isActive },
      });
      return response.data;
    },
    [request]
  );

  const getCompanyLogo = useCallback(
    async (scope: string) => {
      const response = await request<IImage>({
        method: 'get',
        url: endpoints.companies.logo,
        params: {
          scope,
        },
      });
      return response.data;
    },
    [request]
  );

  return {
    listCompanies,
    createCompany,
    getCompany,
    updateCompany,
    checkCompanyField,
    deleteCompany,
    listCompaniesWithoutScope,
    getCompanyLogo,
  };
};

export default useCompanies;
