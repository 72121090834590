import React from 'react';

import { cep, cnpj, currency, phone } from 'b2utils';
import { B2Card } from 'react-b2components';
import { useMutation } from 'react-query';
import { TaxRegimeCodeMap } from 'utils/enums';
import { formatToBrlDate } from 'utils/formats';

import { useAuth } from '@contexts/Auth';
import { useToast } from '@contexts/Toast';
import { useCompanies } from '@hooks';

import { InactivateSwitch } from '@components/InactivateSwitch';
import SectionHeader from '@components/SectionHeader';

import { Session } from '../styles';
import DetailInfo from './DetailInfo';
import LinkInfo from './LinkInfo';
import RateInfo from './RateInfo';
import { SwitchContainer } from './styles';

interface CompanyInfoProps {
  company: ICompany;
  refetch: () => void;
}
const CompanyInfo: React.FC<CompanyInfoProps> = ({ company, refetch }) => {
  const { addToast } = useToast();
  const { deleteCompany } = useCompanies();
  const { isManagementUser } = useAuth();

  const { mutate: handleDeleteCompany } = useMutation(
    () => deleteCompany(company.id, !company.is_active),
    {
      onSuccess: () => {
        addToast('Situação da empresa alterada com sucesso', 'success');
        refetch();
      },
    }
  );

  return (
    <B2Card>
      {isManagementUser && (
        <SwitchContainer>
          <InactivateSwitch
            isActive={company.is_active}
            onChange={handleDeleteCompany}
          />
        </SwitchContainer>
      )}
      <SectionHeader title="Informações da empresa" />
      <Session>
        <div>
          <DetailInfo label="Nome:" description={company.name} />
          <DetailInfo label="CNPJ:" description={cnpj.mask(company.cnpj)} />
          <DetailInfo
            label="Telefone:"
            description={phone.fromObject({
              areaCode: company.phone.area_code,
              number: company.phone.number,
            })}
          />
        </div>
        <div>
          <DetailInfo label="ANTT:" description={company.antt} />
          <LinkInfo label="Site:" description={company.website_url} />
          <DetailInfo label="Id da empresa:" description={company.scope} />
        </div>
        <div>
          <DetailInfo label="Email:" description={company.email} />
          <DetailInfo
            label="Validade do orçamento (em dias):"
            description={company.budget_expiration.toString()}
          />
          <DetailInfo
            label="Inscrição estadual:"
            description={company.state_registration}
          />
        </div>
      </Session>
      <SectionHeader title="Endereço" />
      <Session>
        <div>
          <DetailInfo
            label="CEP:"
            description={cep.mask(company.address.zip_code)}
          />
          <DetailInfo label="Número:" description={company.address.number} />
        </div>
        <div>
          <DetailInfo label="Rua:" description={company.address.street} />
          <DetailInfo label="Bairro:" description={company.address.district} />
        </div>
        <div>
          <DetailInfo
            label="Cidade/Estado:"
            description={`${company.address.city.name}/${company.address.city.state}`}
          />
          <DetailInfo
            label="Complemento:"
            description={company.address.additional_info || 'Não informado'}
          />
        </div>
      </Session>
      <SectionHeader title="Informações de segurança" />
      <Session>
        <div>
          <DetailInfo label="Nome:" description={company.insurer_name} />
          <DetailInfo
            label="Valor do limite por carga da seguradora (R$):"
            description={currency.centsToBrl(
              company.insurer_value_limit_per_load
            )}
          />
          <DetailInfo
            label="Içamento de carga:"
            description={company.is_load_lifting ? 'Sim' : 'Não'}
            hasHighlight
          />
          <DetailInfo
            label="Veículo de meios próprios:"
            description={company.is_self_owned_vehicle ? 'Sim' : 'Não'}
            hasHighlight
          />
        </div>
        <div>
          <DetailInfo
            label="Número da apólice:"
            description={company.insurer_policy_number}
          />
          <DetailInfo
            label="Averbação automática:"
            description={company.is_automatic_endorsement ? 'Sim' : 'Não'}
            hasHighlight
          />
          <DetailInfo
            label="Rastreado:"
            description={company.is_tracked ? 'Sim' : 'Não'}
            hasHighlight
          />
          <DetailInfo
            label="Remoção interna:"
            description={company.is_internal_removal ? 'Sim' : 'Não'}
            hasHighlight
          />
          {company.atm_username && (
            <DetailInfo
              label="Usuário da ATM:"
              description={company.atm_username}
            />
          )}
        </div>
        <div>
          <DetailInfo
            label="CNPJ:"
            description={cnpj.mask(company.insurer_cnpj)}
          />
          <DetailInfo
            label="Escoltado:"
            description={company.is_escorted ? 'Sim' : 'Não'}
            hasHighlight
          />
          <DetailInfo
            label="Operação de carga e descarga:"
            description={company.is_load_unloading_operation ? 'Sim' : 'Não'}
            hasHighlight
          />
          {company.atm_cod && (
            <DetailInfo label="Código da ATM:" description={company.atm_cod} />
          )}
        </div>
      </Session>
      <SectionHeader title="Emissão de CT-e & emissão de MDF-e" />
      <Session>
        <div>
          <DetailInfo
            label="Emissão de CT-e:"
            description={company.cte_emission_is_enabled ? 'Ativo' : 'Inativo'}
            hasHighlight
          />
          {company.cte_series && (
            <DetailInfo
              label="Série do CT-e:"
              description={company.cte_series}
            />
          )}
          <DetailInfo
            label="Número do CT-e inicial:"
            description={company.initial_cte_number || 'Não informado'}
          />
          <DetailInfo
            label="Número do último CT-e emitido:"
            description={company.last_cte_number || 'Não emitido'}
          />
        </div>
        <div>
          <DetailInfo
            label="Emissão de MDF-e:"
            description={company.mdfe_emission_is_enabled ? 'Ativo' : 'Inativo'}
            hasHighlight
          />
          {company.mdfe_series && (
            <DetailInfo
              label="Série do MDF-e:"
              description={company.mdfe_series}
            />
          )}
          <DetailInfo
            label="Número do MDF-e inicial:"
            description={company.initial_mdfe_number || 'Não informado'}
          />
          <DetailInfo
            label="Número do último MDF-e emitido:"
            description={company.last_mdfe_number || 'Não emitido'}
          />
        </div>
        <div>
          <DetailInfo
            label="Regime tributário:"
            description={company.tax_regime}
          />
          <DetailInfo
            label="Código do regime tributário:"
            description={TaxRegimeCodeMap[company.tax_regime_code]}
          />
          <RateInfo label="Alíquotas:" description={company.rates} />
          <LinkInfo
            label="Certificado digital:"
            description="Clique aqui para baixar"
            href={company.digital_certificate?.url}
          />
          {company.digital_certificate_expiration && (
            <DetailInfo
              label="Data de expiração do certificado digital:"
              description={formatToBrlDate(
                company.digital_certificate_expiration
              )}
            />
          )}
        </div>
      </Session>
    </B2Card>
  );
};

export default CompanyInfo;
